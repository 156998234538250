import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-tablero-programador-sst-finished-ssta',
  templateUrl: './tablero-programador-sst-finished-ssta.component.html',
  styleUrls: ['./tablero-programador-sst-finished-ssta.component.scss']
})
export class TableroProgramadorSstFinishedSstaComponent implements OnInit {

  @Input()
  sstsDataProgrammerFinishedSsta:any = []

  @Output()
  eventEmitterUserFilterSearch:EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  searchLocalStorageSst(user:any,status?:any){
    this.eventEmitterUserFilterSearch.emit({user,status})
  }


}
