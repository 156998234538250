import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Route,
  Router,
  RouterStateSnapshot,
  UrlSegment,
  UrlTree,
} from "@angular/router";
import { Observable } from "rxjs";
import { AuthService } from "../authentication/auth.service";
import { RolAdministradorGuard } from "./rol.administrador.guard";
import { RolGerencialGuard } from "./rol.gerencial.guard";
import { RolLiderDePlanificacionGuard } from "./rol.lider.de.planificacion.guard";
import { RolProgramadorLogisticaGuard } from "./rol.programador.logistica.guard";
import { RolProveedorGuard } from "./rol.proveedor.guard";
import { RolTransportistaGuard } from "./rol.transportista.guard";
import { RolUsuarioInternoGuard } from "./rol.usuario.interno.guard";
import { RolVisualizadorGuard } from "./rol.visualizador.guard";

@Injectable({
  providedIn: "root",
})
export class RolGeneralGuard implements CanActivate {
  constructor(
    private router: Router,
    private authService: AuthService,
    private gerencial: RolGerencialGuard,
    private liderDePlanificacionGuard: RolLiderDePlanificacionGuard,
    private programadorLogisticaGuard: RolProgramadorLogisticaGuard,
    private proveedorGuard: RolProveedorGuard,
    private transportistaGuard: RolTransportistaGuard,
    private usuarioInternoGuard: RolUsuarioInternoGuard,
    private administradorGuard: RolAdministradorGuard,
    private rolVisualizadorGuard: RolVisualizadorGuard
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {

    const roles = JSON.parse(this.authService.queryRole()) as string[];

    for (let rol of roles) {
      switch (rol) {
        case "INTERNAL":
          if (!this.checkUsuarioInterno(rol, next.routeConfig.path)) {
            continue;
          }
          return this.usuarioInternoGuard.canActivate(next, state);
        case "VISUALIZER":
          if (!this.checkUsuarioVisualizador(rol, next.routeConfig.path)) {
            continue;
          }
          return this.rolVisualizadorGuard.canActivate(next, state);
        case "ADMIN":
          if (!this.checkAdministrador(rol, next.routeConfig.path)) {
            continue;
          }
          return this.administradorGuard.canActivate(next, state);
        case "MANAGER":
          if (!this.checkGerencial(rol, next.routeConfig.path)) {
            continue;
          }
          return this.gerencial.canActivate(next, state);
        case "PLANNING_LEADER":
          if (!this.checkLiderDePlanificion(rol, next.routeConfig.path)) {
            continue;
          }
          return this.liderDePlanificacionGuard.canActivate(next, state);
        case "PROGRAMMER":
          if (!this.checkProgramadorLogistica(rol, next.routeConfig.path)) {
            continue;
          }
          return this.programadorLogisticaGuard.canActivate(next, state);
        case "SUPPLIER":
          if (!this.checkProveedor(rol, next.routeConfig.path)) {
            continue;
          }
          return this.proveedorGuard.canActivate(next, state);
        case "TRANSPORTER":
          if (!this.checkTransportista(rol, next.routeConfig.path)) {
            continue;
          }
          return this.transportistaGuard.canActivate(next, state);
        default:
          return this.check();
      }
    }
  }

  private check() {
    const rol = this.authService.queryRole();
    return rol == "Usuario Interno";
  }

  /* ****chequeo de rutas del usuario interno **** */
  private checkUsuarioInterno(rol, path) {
    if (path == "solicitudes") {
      return true;
    }
    if (path == "crear-sst") {
      return true;
    }
    if (path == "actualizar-sst/:id") {
      return true;
    }
    if (path == "details-sst/:id") {
      return true;
    }
    if (path == "alertas") {
      return true;
    }
    if (path == "document/SST/:id") {
      return true;
    }
    if (path == "document/actualizar/SST/:id") {
      return true;
    }
    if (path == "roles") {
      return true;
    }
    return false;
  }
  /***************************************************************** */

  /* ****chequeo de rutas del administrador**** */
  private checkAdministrador(rol, path) {
    if (path == "informar-ssta") {
      return true;
    }
    if (path == "crear-sst") {
      return true;
    }
    if (path == "actualizar-sst/:id") {
      return true;
    }
    if (path == "solicitudes") {
      return true;
    }
    if (path == "solicitudes-asignadas") {
      return true;
    }
    if (path == "details-sst/:id") {
      return true;
    }
    if (path == "details-ssta/:id") {
      return true;
    }
    if (path == "transportistas") {
      return true;
    }
    if (path == "actualizar-transportista/:id") {
      return true;
    }
    if (path == "actualizar-ssta/:id") {
      return true;
    }
    if (path == "alta-transportista") {
      return true;
    }
    if (path == "details-transportista/:id") {
      return true;
    }
    if (path == "proveedores") {
      return true;
    }
    if (path == "alta-proveedor") {
      return true;
    }
    if (path == "actualizar-proveedor/:id") {
      return true;
    }
    if (path == "details-proveedor/:id") {
      return true;
    }
    if (path == "programadores") {
      return true;
    }
    if (path == "alta-programador") {
      return true;
    }
    if (path == "actualizar-programador/:id") {
      return true;
    }
    if (path == "details-programador/:id") {
      return true;
    }
    if (path == "document/SST/:id") {
      return true;
    }
    if (path == "roles") {
      return true;
    }
    if (path == "generar-ssta") {
      return true;
    }
    if (path == "transferir-sst") {
      return true;
    }

    if (path == "reportes") {
      return true;
    }
    if (path == "reportes/SST") {
      return true;
    }
    if (path == "reportes/SST/pendientes") {
      return true;
    }
    if (path == "reportes/SST/requirente") {
      return true;
    }
    if (path == "reportes/SSTA") {
      return true;
    }
    if (path == "reportes/SSTA/rechazadas") {
      return true;
    }
    if (path == "reportes/SST/RECEPCION-ASIGNACION") {
      return true;
    }
    if (path == "reportes/SST/recepcion-finalizacion-ssta") {
      return true;
    }
    if (path == "reportes/SST/tiempo") {
      return true;
    }
    if (path == "reportes/SSTA/RECEPCION-ASIGNACION") {
      return true;
    }
    if (path == "reportes/SSTA/tiempo") {
      return true;
    }
    if (path == "reportes/SSTA/tiempo/programadores") {
      return true;
    }
    if (path == "editar-sst-programador/:id") {
      return true;
    }
    if (path == "alertas") {
      return true;
    }

    if (path == "editar-sst-programador") {
      return true;
    }

    if (path == "transporte") {
      return true;
    }
    if (path == "transporte/tractores") {
      return true;
    }
    if (path == "transporte/conductores") {
      return true;
    }
    if (path == "transporte/acoplados") {
      return true;
    }
    if (path == "details-sst/:id") {
      return true;
    }
    if (path == "details-ssta/:id") {
      return true;
    }
    if (path == "document/SST/:id") {
      return true;
    }
    if (path == "document/ver/tractor/:id") {
      return true;
    }
    if (path == "document/ver/conductor/:id") {
      return true;
    }
    if (path == "document/ver/trailer/:id") {
      return true;
    }
    if (path == "document/agregar/tractor/:id") {
      return true;
    }
    if (path == "document/agregar/conductor/:id") {
      return true;
    }
    if (path == "document/agregar/trailer/:id") {
      return true;
    }
    if (path == "document/actualizar/SST/:id") {
      return true;
    }
    if (path == "document/actualizar/tractor/:id") {
      return true;
    }
    if (path == "document/actualizar/conductor/:id") {
      return true;
    }
    if (path == "document/actualizar/trailer/:id") {
      return true;
    }
    if (path == "tablero-general") {
      return true;
    }
    if (path == "delegar-sst") {
      return true;
    }

    return false;
  }
  /***************************************************************** */

  /* ****chequeo de rutas del gerencial**** */
  private checkGerencial(rol, path) {
    if (path == "solicitudes") {
      return true;
    }
    if (path == "solicitudes-asignadas") {
      return true;
    }
    if (path == "alertas") {
      return true;
    }
    if (path == "details-sst/:id") {
      return true;
    }
    if (path == "details-ssta/:id") {
      return true;
    }
    if (path == "transportistas") {
      return true;
    }
    if (path == "actualizar-transportista/:id") {
      return true;
    }
    if (path == "alta-transportista") {
      return true;
    }
    if (path == "details-transportista/:id") {
      return true;
    }

    if (path == "reportes") {
      return true;
    }
    if (path == "reportes/SST") {
      return true;
    }
    if (path == "reportes/SST/pendientes") {
      return true;
    }
    if (path == "reportes/SST/requirente") {
      return true;
    }
    if (path == "reportes/SSTA") {
      return true;
    }
    if (path == "reportes/SSTA/rechazadas") {
      return true;
    }
    if (path == "reportes/SST/recepcion-finalizacion-ssta") {
      return true;
    }
    if (path == "reportes/SST/RECEPCION-ASIGNACION") {
      return true;
    }
    if (path == "reportes/SST/tiempo") {
      return true;
    }
    if (path == "reportes/SSTA/RECEPCION-ASIGNACION") {
      return true;
    }
    if (path == "reportes/SSTA/tiempo") {
      return true;
    }
    if (path == "reportes/SSTA/tiempo/programadores") {
      return true;
    }
    if (path == "document/SST/:id") {
      return true;
    }
    return false;
  }

  /***************************************************************** */

  /* ****chequeo de rutas del lider de planificacion**** */
  private checkLiderDePlanificion(rol, path) {
    if (path == "editar-sst-programador/:id") {
      return true;
    }
    if (path == "solicitudes") {
      return true;
    }
    if (path == "solicitudes-asignadas") {
      return true;
    }
    if (path == "details-sst/:id") {
      return true;
    }
    if (path == "details-ssta/:id") {
      return true;
    }
    if (path == "alertas") {
      return true;
    }
    if (path == "transportistas") {
      return true;
    }
    if (path == "details-transportista/:id") {
      return true;
    }
    if (path == "generar-ssta") {
      return true;
    }
    if (path == "transferir-sst") {
      return true;
    }
    if (path == "actualizar-ssta/:id") {
      return true;
    }
    if (path == "editar-sst-programador") {
      return true;
    }
    if (path == "alta-transportista") {
      return true;
    }
    return false;
  }

  private checkUsuarioVisualizador(rol,path){

    if (path == "solicitudes") {
      return true;
    }
    if (path == "solicitudes-asignadas") {
      return true;
    }
    if (path == "details-sst/:id") {
      return true;
    }
    if (path == "details-ssta/:id") {
      return true;
    }
    if (path == "document/SST/:id") {
      return true;
    }
    if (path == "proveedores") {
      return true;
    }
    if (path == "reportes") {
      return true;
    }
    if (path == "reportes/SST") {
      return true;
    }
    if (path == "reportes/SST/pendientes") {
      return true;
    }
    if (path == "reportes/SST/requirente") {
      return true;
    }
    if (path == "reportes/SSTA") {
      return true;
    }
    if (path == "reportes/SSTA/rechazadas") {
      return true;
    }
    if (path == "reportes/SST/recepcion-finalizacion-ssta") {
      return true;
    }
    if (path == "reportes/SST/RECEPCION-ASIGNACION") {
      return true;
    }
    if (path == "reportes/SST/tiempo") {
      return true;
    }
    if (path == "reportes/SSTA/RECEPCION-ASIGNACION") {
      return true;
    }
    if (path == "reportes/SSTA/tiempo") {
      return true;
    }
    if (path == "reportes/SSTA/tiempo/programadores") {
      return true;
    }

    return false;
  }

  /***************************************************************** */

  /* ****chequeo de rutas del programador logistico**** */
  private checkProgramadorLogistica(rol, path) {
    if (path == "editar-sst-programador/:id") {
      return true;
    }
    if (path == "solicitudes") {
      return true;
    }
    if (path == "solicitudes-asignadas") {
      return true;
    }
    if (path == "details-sst/:id") {
      return true;
    }
    if (path == "details-ssta/:id") {
      return true;
    }
    if (path == "alertas") {
      return true;
    }
    if (path == "transportistas") {
      return true;
    }
    if (path == "details-transportista/:id") {
      return true;
    }
    if (path == "generar-ssta") {
      return true;
    }
    if (path == "transferir-sst") {
      return true;
    }
    if (path == "actualizar-ssta/:id") {
      return true;
    }
    if (path == "editar-sst-programador") {
      return true;
    }
    if (path == "alta-transportista") {
      return true;
    }
    if (path == "document/SST/:id") {
      return true;
    }
    if (path == "document/actualizar/SST/:id") {
      return true;
    }
    if (path == "reportes") {
      return true;
    }
    if (path == "reportes/SST") {
      return true;
    }
    if (path == "reportes/SST/pendientes") {
      return true;
    }
    if (path == "reportes/SST/requirente") {
      return true;
    }
    if (path == "reportes/SSTA") {
      return true;
    }
    if (path == "reportes/SSTA/rechazadas") {
      return true;
    }
    if (path == "reportes/SST/recepcion-finalizacion-ssta") {
      return true;
    }
    if (path == "reportes/SST/RECEPCION-ASIGNACION") {
      return true;
    }
    if (path == "reportes/SST/tiempo") {
      return true;
    }
    if (path == "reportes/SSTA/RECEPCION-ASIGNACION") {
      return true;
    }
    if (path == "reportes/SSTA/tiempo") {
      return true;
    }
    if (path == "reportes/SSTA/tiempo/programadores") {
      return true;
    }
    if (path == "delegar-sst") {
      return true;
    }
    if (path == "tablero-general") {
      return true;
    }
    return false;
  }

  /***************************************************************** */

  /* ****chequeo de rutas del proveedor**** */
  private checkProveedor(rol, path) {
    if (path == "solicitudes") {
      return true;
    }
    if (path == "crear-sst") {
      return true;
    }
    if (path == "actualizar-sst/:id") {
      return true;
    }
    if (path == "details-sst/:id") {
      return true;
    }
    if (path == "alertas") {
      return true;
    }
    if (path == "document/SST/:id") {
      return true;
    }
    if (path == "document/actualizar/SST/:id") {
      return true;
    }
    return false;
  }
  /***************************************************************** */

  /* ****chequeo de rutas del transportista**** */
  private checkTransportista(rol, path) {
    if (path == "informar-ssta") {
      return true;
    }
    if (path == "tablero-transportista") {
      return true;
    }
    if (path == "solicitudes-asignadas") {
      return true;
    }
    if (path == "transporte") {
      return true;
    }
    if (path == "transporte/tractores") {
      return true;
    }
    if (path == "transporte/conductores") {
      return true;
    }
    if (path == "transporte/acoplados") {
      return true;
    }
    if (path == "alertas") {
      return true;
    }
    if (path == "details-sst/:id") {
      return true;
    }
    if (path == "details-ssta/:id") {
      return true;
    }
    if (path == "document/SST/:id") {
      return true;
    }
    if (path == "document/ver/tractor/:id") {
      return true;
    }
    if (path == "document/ver/conductor/:id") {
      return true;
    }
    if (path == "document/ver/trailer/:id") {
      return true;
    }
    if (path == "document/agregar/tractor/:id") {
      return true;
    }
    if (path == "document/agregar/conductor/:id") {
      return true;
    }
    if (path == "document/agregar/trailer/:id") {
      return true;
    }
    if (path == "document/actualizar/tractor/:id") {
      return true;
    }
    if (path == "document/actualizar/conductor/:id") {
      return true;
    }
    if (path == "document/actualizar/trailer/:id") {
      return true;
    }
    return false;
  }
  /***************************************************************** */
}
