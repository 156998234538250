import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReporteSstaComponent } from './pages/reporte-ssta/reporte-ssta.component';
import { ReporteSstComponent } from './pages/reporte-sst/reporte-sst.component';
import { ReporteListadoComponent } from './pages/reporte-listado/reporte-listado.component';
import { PrimengModule } from 'src/app/shared/primeng/primeng.module';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { ReportSstRecepcionHastaAsignacionComponent } from './pages/report-sst-recepcion-hasta-asignacion/report-sst-recepcion-hasta-asignacion.component';
import { ReportSstTiempoComponent } from './pages/report-sst-tiempo/report-sst-tiempo.component';
import { ReportSstaRecepcionHastaAsignacionComponent } from './pages/report-ssta-recepcion-hasta-asignacion/report-ssta-recepcion-hasta-asignacion.component';
import { ReportSstaTiempoComponent } from './pages/report-ssta-tiempo/report-ssta-tiempo.component';
import { ReportSstRequirenteComponent } from './pages/report-sst-requirente/report-sst-requirente.component';
import { ReportesSstaRechazadasComponent } from './pages/reportes-ssta-rechazadas/reportes-ssta-rechazadas.component';
import { ReportSstaTiempoProgramadoresTransportistasComponent } from './pages/report-ssta-tiempo-programadores-transportistas/report-ssta-tiempo-programadores-transportistas.component';
import { ReportSstProgramadorRecepcionFinalizaSstaComponent } from './pages/report-sst-programador-recepcion-finaliza-ssta/report-sst-programador-recepcion-finaliza-ssta.component';



@NgModule({
  declarations: [
    ReporteSstaComponent,
    ReporteSstComponent,
    ReporteListadoComponent,
    ReportSstRecepcionHastaAsignacionComponent,
    ReportSstTiempoComponent,
    ReportSstaRecepcionHastaAsignacionComponent,
    ReportSstaTiempoComponent,
    ReportSstRequirenteComponent,
    ReportesSstaRechazadasComponent,
    ReportSstaTiempoProgramadoresTransportistasComponent,
    ReportSstProgramadorRecepcionFinalizaSstaComponent,
  ],
  imports: [
    CommonModule,PrimengModule,HttpClientModule,FormsModule
  ]
})
export class ReportesModule { }
