
<div class="row">
    <div class="col">
        <div class="text-center" *ngIf="spinnerGraphicAverageTimeSst">
            <span>
                <p-progressSpinner></p-progressSpinner>
            </span>
            <br />
            <span class="text-center">Cargando...</span>
        </div>
    </div>
</div>

<div class="row" [ngClass]="{
    'd-none'  : spinnerGraphicAverageTimeSst
}">
    <div class="col">
        <div id="chart4">
    </div>
</div>

