import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TableroGeneralComponent } from './pages/tablero-general/tablero-general.component';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { PrimengModule } from 'src/app/shared/primeng/primeng.module';
import { ApexchartModule } from 'src/app/shared/apexchart/apexchart.module';
import { TableroProgramadoresComponent } from './components/tablero-programadores/tablero-programadores.component';
import { TableroTransportistasComponent } from './components/tablero-transportistas/tablero-transportistas.component';
import { GraphicSstaRejectedAcceptedComponent } from './components/graphic-ssta-rejected-accepted/graphic-ssta-rejected-accepted.component';
import { GraphicAverageTimeSstComponent } from './components/graphic-average-time-sst/graphic-average-time-sst.component';
import { GraphicAverageTimeSstaComponent } from './components/graphic-average-time-ssta/graphic-average-time-ssta.component';
import { TableroProgramadorSstFinishedSstaComponent } from './components/tablero-programador-sst-finished-ssta/tablero-programador-sst-finished-ssta.component';
import { GraphicProgramadorSstFinishedSstaComponent } from './components/graphic-programador-sst-finished-ssta/graphic-programador-sst-finished-ssta.component';

@NgModule({
  declarations: [
    TableroGeneralComponent,
    TableroProgramadoresComponent,
    TableroTransportistasComponent,
    GraphicSstaRejectedAcceptedComponent,
    GraphicAverageTimeSstComponent,
    GraphicAverageTimeSstaComponent,
    TableroProgramadorSstFinishedSstaComponent,
    GraphicProgramadorSstFinishedSstaComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    PrimengModule,
    ApexchartModule
  ],
  exports : [
    TableroGeneralComponent
  ]
})
export class TableroGeneralModule { }
