import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AlertasComponent } from './components/alertas/pages/alertas/alertas.component';
import { DocumentAddConductorComponent } from './components/documentacion/document-add-conductor/document-add-conductor.component';
import { DocumentAddTractorComponent } from './components/documentacion/document-add-tractor/document-add-tractor.component';
import { DocumentAddTrailerComponent } from './components/documentacion/document-add-trailer/document-add-trailer.component';
import { DocumentConductorComponent } from './components/documentacion/document-conductor/document-conductor.component';
import { DocumentSstComponent } from './components/documentacion/document-sst/document-sst.component';
import { DocumentTractorComponent } from './components/documentacion/document-tractor/document-tractor.component';
import { DocumentTrailerComponent } from './components/documentacion/document-trailer/document-trailer.component';
import { DocumentUpdateConductorComponent } from './components/documentacion/document-update-conductor/document-update-conductor.component';
import { DocumentUpdateSstComponent } from './components/documentacion/document-update-sst/document-update-sst.component';
import { DocumentUpdateTractorComponent } from './components/documentacion/document-update-tractor/document-update-tractor.component';
import { DocumentUpdateTrailerComponent } from './components/documentacion/document-update-trailer/document-update-trailer.component';
import { CreateProgramadorComponent } from './components/programador/create-programador/create-programador.component';
import { DetailsProgramadorComponent } from './components/programador/details-programador/details-programador.component';
import { ListProgramadorComponent } from './components/programador/list-programador/list-programador.component';
import { UpdateProgramadorComponent } from './components/programador/update-programador/update-programador.component';
import { AltaProveedorComponent } from './components/proveedor/alta-proveedor/alta-proveedor.component';
import { DetailsProveedorComponent } from './components/proveedor/details-proveedor/details-proveedor.component';
import { EditProveedorComponent } from './components/proveedor/edit-proveedor/edit-proveedor.component';
import { ListProveedorComponent } from './components/proveedor/list-proveedor/list-proveedor.component';
import { ReportSstRecepcionHastaAsignacionComponent } from './components/reportes/pages/report-sst-recepcion-hasta-asignacion/report-sst-recepcion-hasta-asignacion.component';
import { ReportSstRequirenteComponent } from './components/reportes/pages/report-sst-requirente/report-sst-requirente.component';
import { ReportSstTiempoComponent } from './components/reportes/pages/report-sst-tiempo/report-sst-tiempo.component';
import { ReportSstaRecepcionHastaAsignacionComponent } from './components/reportes/pages/report-ssta-recepcion-hasta-asignacion/report-ssta-recepcion-hasta-asignacion.component';
import { ReportSstaTiempoComponent } from './components/reportes/pages/report-ssta-tiempo/report-ssta-tiempo.component';
import { ReporteListadoComponent } from './components/reportes/pages/reporte-listado/reporte-listado.component';
import { ReporteSstComponent } from './components/reportes/pages/reporte-sst/reporte-sst.component';
import { ReporteSstaComponent } from './components/reportes/pages/reporte-ssta/reporte-ssta.component';
import { ReportesSstaRechazadasComponent } from './components/reportes/pages/reportes-ssta-rechazadas/reportes-ssta-rechazadas.component';
import { CreateSstComponent } from './components/sst/pages/create-sst/create-sst.component';
import { DetailsSstComponent } from './components/sst/pages/details-sst/details-sst.component';
import { DetailsSstaComponent } from './components/ssta/pages/details-ssta/details-ssta.component';
import { EditSstProgramadorComponent } from './components/sst/pages/edit-sst-programador/edit-sst-programador.component';
import { GenerarSstaComponent } from './components/ssta/pages/generar-ssta/generar-ssta.component';
import { InformacionDeTransporteComponent } from './components/vehiculos/pages/informacion-de-transporte/informacion-de-transporte.component';
import { InformarSstaComponent } from './components/ssta/pages/informar-ssta/informar-ssta.component';
import { ListSstComponent } from './components/sst/pages/list-sst/list-sst.component';
import { ListSstaComponent } from './components/ssta/pages/list-ssta/list-ssta.component';
import { TableroTransportistaComponent } from './components/transportista/tablero-transportista/tablero-transportista.component';
import { TransferirSstComponent } from './components/sst/pages/transferir-sst/transferir-sst.component';
import { UpdateSstComponent } from './components/sst/pages/update-sst/update-sst.component';
import { UpdateSstaComponent } from './components/ssta/pages/update-ssta/update-ssta.component';
import { AltaTransportistaComponent } from './components/transportista/alta-transportista/alta-transportista.component';
import { DetailsTransportistaComponent } from './components/transportista/details-transportista/details-transportista.component';
import { EditTransportistaComponent } from './components/transportista/edit-transportista/edit-transportista.component';
import { ListTransportistaComponent } from './components/transportista/list-transportista/list-transportista.component';
import { AuthGuard } from './core/guards/auth.guard';
import { RolGeneralGuard } from './core/guards/rol.general.guard';
import { RolTransportistaGuard } from './core/guards/rol.transportista.guard';
import { HomeComponent } from './modules/home/pages/home/home.component';
import { CambiarContraseniaComponent } from './modules/login/pages/cambiar-contrasenia/cambiar-contrasenia.component';
import { LoginComponent } from './modules/login/pages/login/login.component';
import { RecuperarComponent } from './modules/login/pages/recuperar/recuperar.component';
import { RegistroComponent } from './modules/login/pages/registro/registro.component';
import { BrowserUtils } from '@azure/msal-browser';
import { ReportSstaTiempoProgramadoresTransportistasComponent } from './components/reportes/pages/report-ssta-tiempo-programadores-transportistas/report-ssta-tiempo-programadores-transportistas.component';
import { RolesComponent } from './components/roles/pages/list-roles/roles.component';
import { DividirSstComponent } from './components/sst/pages/dividir-sst/dividir-sst.component';
import { DelegateSstComponent } from './components/sst/pages/delegate-sst/delegate-sst.component';
import { TractoresComponent } from './components/vehiculos/pages/tractores/tractores.component';
import { ConductoresComponent } from './components/vehiculos/pages/conductores/conductores.component';
import { AcopladosComponent } from './components/vehiculos/pages/acoplados/acoplados.component';
import { ListTransportComponent } from './components/vehiculos/pages/list-transport/list-transport.component';
import { TableroGeneralComponent } from './components/tablero-general/pages/tablero-general/tablero-general.component';
import { ReportSstProgramadorRecepcionFinalizaSstaComponent } from './components/reportes/pages/report-sst-programador-recepcion-finaliza-ssta/report-sst-programador-recepcion-finaliza-ssta.component';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'home', component: HomeComponent, canActivate: [AuthGuard]},
  { path: 'home(toolbar:home)', component: HomeComponent, canActivate: [AuthGuard] },
  { path: 'solicitudes', component: ListSstComponent, canActivate: [AuthGuard, RolGeneralGuard] },
  { path: 'solicitudes-asignadas', component: ListSstaComponent, canActivate: [AuthGuard, RolGeneralGuard] },
  { path: 'crear-sst', component: CreateSstComponent, canActivate: [AuthGuard] },
  { path: 'actualizar-sst/:id', component: UpdateSstComponent, canActivate: [AuthGuard] },
  { path: 'details-sst/:id', component: DetailsSstComponent, canActivate: [AuthGuard] },
  { path: 'login', component: LoginComponent },
  { path: 'registro', component: RegistroComponent },
  { path: 'olvido-contraseña', component: RecuperarComponent },
  { path: 'activar/:token', component: CambiarContraseniaComponent },

  { path: 'tablero-transportista', component: TableroTransportistaComponent, canActivate: [AuthGuard, RolGeneralGuard] },//solo transportista
  { path: 'informar-ssta', component: InformarSstaComponent, canActivate: [AuthGuard, RolGeneralGuard] },//solo transportista
  
  { path: 'transporte', component: ListTransportComponent, canActivate: [AuthGuard, RolGeneralGuard] },//solo transportista
  { path: 'transporte/tractores', component: TractoresComponent, canActivate: [AuthGuard, RolGeneralGuard] },//solo transportista
  { path: 'transporte/acoplados', component: AcopladosComponent, canActivate: [AuthGuard, RolGeneralGuard] },//solo transportista
  { path: 'transporte/conductores', component: ConductoresComponent, canActivate: [AuthGuard, RolGeneralGuard] },//solo transportista

  { path: 'alertas', component: AlertasComponent, canActivate: [AuthGuard, RolGeneralGuard] }, //solo rol admin

  { path: 'transportistas', component: ListTransportistaComponent, canActivate: [AuthGuard, RolGeneralGuard] },
  { path: 'alta-transportista', component: AltaTransportistaComponent, canActivate: [AuthGuard, RolGeneralGuard] },
  { path: 'actualizar-transportista/:id', component: EditTransportistaComponent, canActivate: [AuthGuard, RolGeneralGuard] },
  { path: 'details-transportista/:id', component: DetailsTransportistaComponent, canActivate: [AuthGuard, RolGeneralGuard] },

  { path: 'proveedores', component: ListProveedorComponent, canActivate: [AuthGuard, RolGeneralGuard] },
  { path: 'alta-proveedor', component: AltaProveedorComponent, canActivate: [AuthGuard, RolGeneralGuard] },
  { path: 'actualizar-proveedor/:id', component: EditProveedorComponent, canActivate: [AuthGuard, RolGeneralGuard] },
  { path: 'details-proveedor/:id', component: DetailsProveedorComponent, canActivate: [AuthGuard, RolGeneralGuard] },

  { path: 'generar-ssta', component: GenerarSstaComponent, canActivate: [AuthGuard, RolGeneralGuard] },
  { path: 'transferir-sst', component: TransferirSstComponent, canActivate: [AuthGuard, RolGeneralGuard] },
  { path: 'details-ssta/:id', component: DetailsSstaComponent, canActivate: [AuthGuard, RolGeneralGuard] },
  { path: 'actualizar-ssta/:id', component: UpdateSstaComponent, canActivate: [AuthGuard, RolGeneralGuard] },
  { path: 'editar-sst-programador/:id', component: DividirSstComponent, canActivate: [AuthGuard, RolGeneralGuard] },

  { path: 'programadores', component: ListProgramadorComponent, canActivate: [AuthGuard, RolGeneralGuard] },
  { path: 'alta-programador', component: CreateProgramadorComponent, canActivate: [AuthGuard, RolGeneralGuard] },
  { path: 'actualizar-programador/:id', component: UpdateProgramadorComponent, canActivate: [AuthGuard, RolGeneralGuard] },
  { path: 'details-programador/:id', component: DetailsProgramadorComponent, canActivate: [AuthGuard, RolGeneralGuard] },

  { path: 'roles', component: RolesComponent, canActivate: [AuthGuard, RolGeneralGuard] },
  
  { path: 'document/SST/:id', component: DocumentSstComponent, canActivate: [AuthGuard, RolGeneralGuard] },
  { path: 'document/ver/tractor/:id', component: DocumentTractorComponent, canActivate: [AuthGuard, RolGeneralGuard] },
  { path: 'document/ver/conductor/:id', component: DocumentConductorComponent, canActivate: [AuthGuard, RolGeneralGuard] },
  { path: 'document/ver/trailer/:id', component: DocumentTrailerComponent, canActivate: [AuthGuard, RolGeneralGuard] },

  { path: 'document/agregar/tractor/:id', component: DocumentAddTractorComponent, canActivate: [AuthGuard, RolGeneralGuard] },
  { path: 'document/agregar/conductor/:id', component: DocumentAddConductorComponent, canActivate: [AuthGuard, RolGeneralGuard] },
  { path: 'document/agregar/trailer/:id', component: DocumentAddTrailerComponent, canActivate: [AuthGuard, RolGeneralGuard] },
  
  { path: 'document/actualizar/tractor/:id', component: DocumentUpdateTractorComponent, canActivate: [AuthGuard, RolGeneralGuard] },
  { path: 'document/actualizar/conductor/:id', component: DocumentUpdateConductorComponent, canActivate: [AuthGuard, RolGeneralGuard] },
  { path: 'document/actualizar/trailer/:id', component: DocumentUpdateTrailerComponent, canActivate: [AuthGuard, RolGeneralGuard] },
  { path: 'document/actualizar/SST/:id', component: DocumentUpdateSstComponent, canActivate: [AuthGuard, RolGeneralGuard] },
  
  { path: 'reportes', component: ReporteListadoComponent, canActivate: [AuthGuard, RolGeneralGuard] },
  { path: 'reportes/SST', component: ReporteSstComponent, canActivate: [AuthGuard, RolGeneralGuard] },
  { path: 'reportes/SST/pendientes', component: ReporteSstComponent, canActivate: [AuthGuard, RolGeneralGuard] },
  { path: 'reportes/SST/requirente', component: ReportSstRequirenteComponent, canActivate: [AuthGuard, RolGeneralGuard] },
  { path: 'reportes/SSTA', component: ReporteSstaComponent, canActivate: [AuthGuard, RolGeneralGuard] },
  { path: 'reportes/SSTA/rechazadas', component: ReportesSstaRechazadasComponent, canActivate: [AuthGuard, RolGeneralGuard] },
  { path: 'reportes/SST/recepcion-finalizacion-ssta', component: ReportSstProgramadorRecepcionFinalizaSstaComponent, canActivate: [AuthGuard, RolGeneralGuard] },
  { path: 'reportes/SST/RECEPCION-ASIGNACION', component: ReportSstRecepcionHastaAsignacionComponent, canActivate: [AuthGuard, RolGeneralGuard] },
  { path: 'reportes/SSTA/RECEPCION-ASIGNACION', component: ReportSstaRecepcionHastaAsignacionComponent, canActivate: [AuthGuard, RolGeneralGuard] },
  { path: 'reportes/SST/tiempo', component: ReportSstTiempoComponent, canActivate: [AuthGuard, RolGeneralGuard] },
  { path: 'reportes/SSTA/tiempo', component: ReportSstaTiempoComponent, canActivate: [AuthGuard, RolGeneralGuard] },
  { path: 'reportes/SSTA/tiempo/programadores', component: ReportSstaTiempoProgramadoresTransportistasComponent, canActivate: [AuthGuard, RolGeneralGuard] },
  
  { path: 'delegar-sst', component: DelegateSstComponent, canActivate: [AuthGuard, RolGeneralGuard] },

  { path: 'tablero-general', component: TableroGeneralComponent, canActivate: [AuthGuard, RolGeneralGuard] },


 
  { path: '**', redirectTo: 'home'},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload',enableTracing: false, relativeLinkResolution: 'legacy' })], // <-- debugging purposes only
  exports: [RouterModule]
})
export class AppRoutingModule {
}
