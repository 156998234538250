<p-table [value]="sstsDataTransportistas.usuarios" dataKey="id" [scrollable]="true"
scrollHeight="35vh" [rowHover]="true" [tableStyle]="{ 'min-width': '50rem' }"
styleClass="p-datatable p-datatable-striped ">
<ng-template pTemplate="header">
    <tr>
        <th>Usuario</th>
        <th>Aceptada</th>
        <th>Cancelada</th>
        <th>Eliminada</th>
        <th>En curso</th>
        <th>Finalizada</th>
        <th>Informada</th>
        <th>Pendiente</th>
        <th>Pend. de autorización</th>
        <th>Rechazada</th>
        <th>Total General</th>
        <th>% Finalizada</th>
        <th>% Cerrada</th>
    </tr>
</ng-template>
<ng-template pTemplate="body" let-usuario>
    <tr>
        <td class="cursor" (click)="searchLocalStorageSsta(usuario.id)">{{usuario.username}}
        </td>
        <td class="cursor" (click)="searchLocalStorageSsta(usuario.id,'ACCEPTED')">
            {{usuario.aceptadas}}</td>
        <td class="cursor" (click)="searchLocalStorageSsta(usuario.id,'CANCELLED')">
            {{usuario.canceladas}}</td>
        <td class="cursor" (click)="searchLocalStorageSsta(usuario.id,'REJECTED')">
            {{usuario.eliminadas}}</td>
        <td class="cursor" (click)="searchLocalStorageSsta(usuario.id,'IN_PROGRESS')">
            {{usuario.curso}}</td>
        <td class="cursor" (click)="searchLocalStorageSsta(usuario.id,'FINISHED')">
            {{usuario.finalizadas}}</td>
        <td class="cursor" (click)="searchLocalStorageSsta(usuario.id,'INFORMED')">
            {{usuario.informadas}}</td>
        <td class="cursor" (click)="searchLocalStorageSsta(usuario.id,'PENDING')">
            {{usuario.pendientes}}</td>
        <td class="cursor">{{usuario.autorizadas}}</td>
        <td class="cursor" (click)="searchLocalStorageSsta(usuario.id,'REJECTED')">
            {{usuario.rechazadas}}</td>
        <td>{{usuario.total}}</td>
        <td>{{usuario.porcentajeFinalizadas.toFixed(2)}}%</td>
        <td>{{usuario.porcentajeCerradas.toFixed(2)}}%</td>
        <!-- <td></td> -->
    </tr>
</ng-template>
<ng-template pTemplate="emptymessage" class="w-100">
    <tr>
        <td colspan="12">
            <p-messages class="w-100"
                [value]="[{ severity: 'info', detail: 'No se encontraron resultados' }]"
                [enableService]="false" [closable]="false">
            </p-messages>
        </td>
    </tr>
</ng-template>
<ng-template pTemplate="footer" let-usuario>
    <tr>
        <td>Total general:</td>
        <td>{{sstsDataTransportistas.aceptadas}}</td>
        <td>{{sstsDataTransportistas.canceladas}}</td>
        <td>{{sstsDataTransportistas.eliminadas}}</td>
        <td>{{sstsDataTransportistas.curso}}</td>
        <td>{{sstsDataTransportistas.finalizadas}}</td>
        <td>{{sstsDataTransportistas.informadas}}</td>
        <td>{{sstsDataTransportistas.pendientes}}</td>
        <td>{{sstsDataTransportistas.autorizadas}}</td>
        <td>{{sstsDataTransportistas.rechazadas}}</td>
        <td>{{sstsDataTransportistas.totalGeneral}}</td>
        <td></td>
        <td></td>
    </tr>
</ng-template>
</p-table>