import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { of, concat } from "rxjs";
import { concatMap, tap } from "rxjs/operators";

@Component({
  selector: 'app-graphic-programador-sst-finished-ssta',
  templateUrl: './graphic-programador-sst-finished-ssta.component.html',
  styleUrls: ['./graphic-programador-sst-finished-ssta.component.scss']
})
export class GraphicProgramadorSstFinishedSstaComponent implements OnInit {

  @Input()
  sstsDataProgrammerFinishedSsta = [];

  spinnerGraphicAverageTimeSst = true;

  chart4: any;
  xaxisChart1 = [];
  graphic4: any = null;
  dataChart1: any = [];
  maxYaxisChart1: any;
  labelChart1: any = [];
  enableSeriesIndex: any = [];

  constructor() {
    this.chart4 = {
      series: [],
      chart: {
        type: "line",
        height: 350,
        stacked: true,
        zoom: {
          enabled: true,
        },
        toolbar: {
          tools: {
            zoomin: true,
            zoomout: true,
            selection: true,
            pan: true,
            reset: true,
          },
        },
      },
      labels: [],
      stroke: {
        width: [0, 4],
        colors: ["#fff"],
      },
      dataLabels: {
        enabled: true,
        // enabledOnSeries : [4,5,6],
        enabledOnSeries: null,
        offsetY: 0,
        // textAnchor: "end",
        formatter: function (val, opts) {
          return val || "";
        },
        // offsetY: ,
        // distributed: true,
        style: {
          fontSize: "1em",
          fontFamily: "Helvetica, Arial, sans-serif",
          fontWeight: "bold",
          colors: undefined,
        },
        background: {
          enabled: true,
          foreColor: '#fff',
          padding: 0,
          borderRadius: 1,
          borderWidth: 1,
          borderColor: '#fff',
          opacity: 0.9,
          dropShadow: {
            enabled: false,
            top: 1,
            left: 1,
            blur: 1,
            color: '#000',
            opacity: 0.45
          }
        },
        dropShadow: {
          enabled: true,
          left: 2,
          top: 2,
          opacity: 0.5,
        },
      },
      plotOptions: {
        bar: {
          // columnWidth: '55%',
          horizontal: false,
          dataLabels: {
            // position: 'center'
          }
        },
      },
      fill: {
        opacity: 1,
      },
      xaxis: {
        categories: this.xaxisChart1,
        labels: {
          // rotateAlways: true,
          // rotate: -45,
          formatter: function (value) {
            return value;
          },
          // tickPlacement: 'on'
        },
      },
      yaxis: [],
      tooltip: {
        enabled: true,
        shared: true,
        x: {
          show: true,
          format: "dd MMM",
          formatter: "n",
        },
        y: {
          formatter: function (val, opts) {
            return val ? val : val;
          },
          title: {
            formatter: (seriesName) => seriesName,
          },
        },
        z: {
          formatter: "c",
          title: "Size: ",
        },
      },
      legend: {
        show: false,
        position: "top",
        horizontalAlign: "left",
      },
    };
  }
  ngOnChanges(changes: SimpleChanges): void {

    // this.ngOnInit()
  }

  ngOnDestroy(): void {
  }

  ngOnInit(): void {
    of({})
      .pipe(
        tap({
          next: (data) => {
            this.graphicInsert();
          },
          error: (error) => { },
          complete: () => {
            this.spinnerGraphicAverageTimeSst = false;
          },
        })
      )
      .subscribe();
  }

  graphicInsert() {
    const chartElement = document.querySelector("#chart4");

    if (this.graphic4) {
      this.graphic4.destroy();
      this.xaxisChart1 = [];
      this.dataChart1 = [];
      this.chart4.dataLabels.enabledOnSeries = null;
    }

    this.sstsDataProgrammerFinishedSsta.forEach((mes, index) => {
      const split = mes.mes.split(" ");
      this.xaxisChart1.push(
        split[0].substring(0, 3).toUpperCase() + " " + split[1]
      );
    });

    if (chartElement) {
      const sstsData = {
        name: "SSTs",
        group: "meses",
        type: "column",
        data: [],
        color: this.getRandomColor(),
      };

      const tiempoPromedio = {
        name: "Tiempo promedio",
        type: "line",
        group: "meses",
        data: [],
        color: this.getRandomColor(),
      };

      const programadores = [];
      const tiempoProgramadores = [];

      this.sstsDataProgrammerFinishedSsta.forEach((mes, index) => {
        if (mes.transportistas.length <= 0) {
          sstsData.data.push(mes.cantidad);
          tiempoPromedio.data.push(mes.tiempoPromedio);
        } else {
          mes.transportistas.forEach((programador, index) => {
            let find = programadores.find((element) => {
              return element.group == programador.username;
            });
            if (!find) {
              let color = this.getRandomColor();
              programadores.push({
                name: "SSTs " + programador.username,
                group: programador.username,
                type: "column",
                data: [],
                color: color,
              });
              tiempoProgramadores.push({
                name: "Tiempo promedio " + programador.username,
                group: programador.username,
                type: "line",
                data: [],
                color: color,
              });
            }
          });
        }
      });

      this.sstsDataProgrammerFinishedSsta.forEach((mes, index) => {
        const mesFind = this.sstsDataProgrammerFinishedSsta.find((mesSearch) => { return mesSearch.mes.includes(mes.mes) });
        const indexMes = this.sstsDataProgrammerFinishedSsta.indexOf(mesFind);
        mes.transportistas.forEach((programador, index) => {
          let findGroupSsta = programadores.find((element) => { return element.group.includes(programador.username) });
          let findGroupTiempo = tiempoProgramadores.find((element) => { return element.group.includes(programador.username)})

          findGroupSsta.data[indexMes] = programador.cantidad;
          findGroupTiempo.data[indexMes] = programador.tiempoPromedio;
        });
      });

      if (programadores.length > 0) {
        let maxDataSst = 0;
        let maxTiempo = 0;

        const allDataProgramadores = programadores
          .map((t) => t.data) // Obtiene todos los arrays data
          .reduce((acc, val) => acc.concat(val), []) // Aplana el array
          .filter(Number.isFinite);
        const allDataTiempo = tiempoProgramadores
          .map((t) => t.data) // Obtiene todos los arrays data
          .reduce((acc, val) => acc.concat(val), []) // Aplana el array
          .filter(Number.isFinite);

        of({})
          .pipe(
            tap({
              next: (data) => {
                maxDataSst =
                  allDataProgramadores.length > 0
                    ? Math.max(...allDataProgramadores)
                    : 0;
                maxTiempo =
                  allDataTiempo.length > 0 ? Math.max(...allDataTiempo) : 0;
                this.maxYaxisChart1 =
                  Math.max(maxDataSst, maxTiempo) + 50;
              },
              error: (error) => { },
            }),
            concatMap(() =>
              concat(
                of(
                  this.dataChart1.push(...programadores),
                  this.dataChart1.push(...tiempoProgramadores)
                )
              )
            ),
            concatMap(() =>
              concat(
                of(
                  (this.chart4.yaxis = [
                    {
                      seriesName: [...this.getSeriesName(tiempoProgramadores)],
                      opposite: true,
                      min: 0,
                      max: maxTiempo + 5,
                      title: {
                        text: "Tiempo",
                      },
                      labels: {
                        formatter: function (value) {
                          return parseInt(value);
                        },
                        colors: "#CCCCCC",
                      },
                    },
                    {
                      seriesName: [
                        ...this.getSeriesName(programadores),
                      ],
                      min: 0,
                      max: maxDataSst  + 100,
                      title: {
                        text: "SSTs",
                      },
                      labels: {
                        formatter: function (value) {
                          return parseInt(value);
                        },
                      },
                    },
                  ])
                )
              )
            )
          )
          .subscribe();

        if (this.dataChart1.length > 3) {
          this.chart4.dataLabels.enabledOnSeries = this.getIndexTimeGraphic();
        }
      } else {
        const allDataSst = sstsData.data.length > 0 ? Math.max(...sstsData.data) : 0;
        const allDataTiempo = tiempoPromedio.data.length ? Math.max(...tiempoPromedio.data) : 0;

        this.maxYaxisChart1 = Math.max(allDataSst, allDataTiempo) + 50;

        this.dataChart1.push(sstsData);
        this.dataChart1.push(tiempoPromedio);

        this.chart4.yaxis = [
          {
            seriesName: [...this.getSeriesName([tiempoPromedio])],
            opposite: true,
            min: 0,
            max: allDataTiempo + 5,
            title: {
              text: "Tiempo",
            },
            labels: {
              formatter: function (value) {
                return parseInt(value);
              },
              colors: "#CCCCCC",
            },
            tooltip: {
              enabled: false,
              offsetX: 0,
          },
          },
          {
            seriesName: [...this.getSeriesName([sstsData])],
            min: 0,
            max: allDataSst  + 10,
            title: {
              text: "SSTs",
            },
            labels: {
              formatter: function (value) {
                return parseInt(value);
              },
              colors: "#CCCCCC",
            },
          },

        ];
      }
      this.chart4.labels = this.xaxisChart1;
      this.chart4.series = this.dataChart1;

      this.graphic4 = new ApexCharts(chartElement, this.chart4);

      this.graphic4.render();
    }
  }

  getSeriesName(serie: any[]) {
    const seriesName = [];

    serie.forEach((element) => {
      seriesName.push(element.name);
    });

    return seriesName;
  }

  getIndexTimeGraphic() {
    const indices = this.dataChart1
      .map((item, index) => item.name.includes('Tiempo') ? index : -1)
      .filter(index => index !== -1);
    return indices
  }

  getRandomColor(): string {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 3; i++) {
      // Limita los valores de los colores a una gama oscura (0-7)
      const randomValue = Math.floor(Math.random() * 8);
      color += letters[randomValue] + letters[randomValue]; // Duplícalo para asegurar que sea un color medio a oscuro
    }
    return color;
  }

}

